<template>
  <div class="religiosidade__background">
    <router-view></router-view>
    <ScrollTop />
  </div>
</template>

<script>
import ScrollTop from "@/components/ScrollTop.vue";

export default {
  components: { ScrollTop },
  data() {
    return {};
  },
};
</script>

<style scoped>
.religiosidade__background {
  background-image: url("../../assets/conteudos_religiosidade_background.png");
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f9f7ed;
}
</style>
