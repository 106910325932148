import { render, staticRenderFns } from "./ReligiosidadePopular.vue?vue&type=template&id=1dcf600e&scoped=true&"
import script from "./ReligiosidadePopular.vue?vue&type=script&lang=js&"
export * from "./ReligiosidadePopular.vue?vue&type=script&lang=js&"
import style0 from "./ReligiosidadePopular.vue?vue&type=style&index=0&id=1dcf600e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dcf600e",
  null
  
)

export default component.exports